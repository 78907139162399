import { render, staticRenderFns } from "./SuspendedCompany.vue?vue&type=template&id=5d35c4ed&scoped=true"
import script from "./SuspendedCompany.vue?vue&type=script&lang=js"
export * from "./SuspendedCompany.vue?vue&type=script&lang=js"
import style0 from "./SuspendedCompany.vue?vue&type=style&index=0&id=5d35c4ed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d35c4ed",
  null
  
)

export default component.exports